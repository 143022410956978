// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

type Props = {
  children: React.Node,
};

const query = graphql`
  query {
    file(relativePath: { eq: "photo-frame.png" }) {
      publicURL
    }
  }
`;

const PhotoFrame = ({ children }: Props) => {
  const { file } = useStaticQuery(query);

  return <Border bgUrl={file.publicURL}>{children}</Border>;
};

const Border = styled.div((props) => ({
  border: '20px solid',
  borderImageSource: `url(${props.bgUrl})`,
  borderImageSlice: '33%',
  borderImageRepeat: 'repeat',
  padding: '0.5rem',
}));

export default PhotoFrame;
