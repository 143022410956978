// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import Section from '../molecules/Section';
import PhotoFrame from '../molecules/PhotoFrame';
import Badge from '../atoms/Badge';
import StatsItem from '../molecules/StatsItem';
import OrnamentalButton from '../atoms/OrnamentalButton';

type Props = {
  data: {
    microcmsPuppy: {
      decide: Boolean,
      breed_of_dog: String,
      birthday: String,
      gender: String,
      hair_color: String,
      photo: {
        url: String,
      },
      comment: String,
    },
  },
};

const PuppyPostPage = ({ data }: Props) => {
  const theme = useTheme();

  return (
    <Section title="仔犬情報">
      <PhotoLayout>
        <PhotoFrame>
          <img src={data.microcmsPuppy.photo.url} alt="仔犬画像" />
        </PhotoFrame>
      </PhotoLayout>
      {data.microcmsPuppy.decide ? (
        <CenterBadgeLayout>
          <Badge bgColor={theme.colors.secondary} text="オーナー様決定" wide />
        </CenterBadgeLayout>
      ) : null}
      <Root>
        <StatsItem label="犬種" value={data.microcmsPuppy.breed_of_dog} />
        <StatsItem label="生年月日" value={data.microcmsPuppy.birthday} />
        <StatsItem label="性別" value={data.microcmsPuppy.gender} />
        <StatsItem label="毛色" value={data.microcmsPuppy.hair_color} />
        <CenterBadgeLayout>
          <Badge bgColor={theme.colors.primary} text="コメント" wide />
        </CenterBadgeLayout>
        <Comment dangerouslySetInnerHTML={{ __html: data.microcmsPuppy.comment }} />
        <ButtonLayout>
          <Link to="/puppy">
            <OrnamentalButton text="仔犬情報一覧に戻る" />
          </Link>
        </ButtonLayout>
      </Root>
    </Section>
  );
};

const Root = styled.div((props) => ({
  margin: '0 auto',
  maxWidth: props.theme.breakpoints.tablet,
}));

const PhotoLayout = styled.div((props) => ({
  margin: '0 auto 2rem',
  maxWidth: props.theme.breakpoints.tablet / 2,
}));

const CenterBadgeLayout = styled.div({
  margin: '2rem 0',
  textAlign: 'center',
});

const ButtonLayout = styled.div({
  margin: '2rem auto 3rem',
  textAlign: 'center',
});

const Comment = styled.div({
  margin: '0 auto',
  '& > p': {
    lineHeight: '1.2rem',
    textAlign: 'center',
  },
});

export const pageQuery = graphql`
  query PuppyPostBySlug($slug: String!) {
    microcmsPuppy(fields: { slug: { eq: $slug } }) {
      id
      decide
      breed_of_dog
      birthday
      gender
      hair_color
      photo {
        url
      }
      comment
    }
  }
`;

export default PuppyPostPage;
