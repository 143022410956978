// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import Bullet from '../atoms/Bullet';
import HorizontalLine from '../atoms/HorizontalLine';

type Props = {
  text: string,
};

const SectionHeader = ({ text }: Props) => (
  <div>
    <Header>
      <BulletLayout>
        <Bullet />
      </BulletLayout>
      <Label>{text}</Label>
      <BulletLayout>
        <Bullet />
      </BulletLayout>
    </Header>
    <RuleLayout>
      <HorizontalLine shape="curly" />
    </RuleLayout>
  </div>
);

const Header = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const BulletLayout = styled.div((props) => ({
  margin: '2px 0.5rem',
  width: 20,
  [props.theme.mediaQuery.minTablet]: {
    margin: '7px 0.5rem',
  },
}));

const Label = styled.h2((props) => ({
  color: props.theme.colors.primary,
  fontSize: '1.25rem',
  fontWeight: 'normal',
  marginBottom: 0,
  [props.theme.mediaQuery.minTablet]: {
    fontSize: '1.75rem',
  },
}));

const RuleLayout = styled.div((props) => ({
  margin: '0 auto',
  padding: '0.5rem 0 0',
  width: '90%',
  [props.theme.mediaQuery.minTablet]: {
    width: 548,
  },
}));

export default SectionHeader;
