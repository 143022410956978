// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

type Props = {
  bgColor: String,
  color: String,
  text: String,
  wide: Boolean,
};

const Badge = (props: Props) => {
  const theme = useTheme();
  const { bgColor = theme.colors.primary, color = '#fff', text, wide = false } = props;

  return (
    <Span bgColor={bgColor} color={color} wide={wide}>
      {text}
    </Span>
  );
};

const Span = styled.span((props) => ({
  backgroundColor: props.bgColor,
  borderRadius: 3,
  color: props.color,
  fontWeight: 'bold',
  padding: props.wide ? '0.2rem 1.5rem' : '0.2rem 0.8rem',
}));

export default Badge;
