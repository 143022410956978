// @flow strict
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

type Props = {
  text: String,
};

const query = graphql`
  query {
    file(relativePath: { eq: "button-frame.png" }) {
      publicURL
    }
  }
`;

const OrnamentalButton = ({ text }: Props) => {
  const { file } = useStaticQuery(query);

  return <Border bgUrl={file.publicURL}>{text}</Border>;
};

const Border = styled.div((props) => ({
  border: '20px solid',
  borderImageSource: `url(${props.bgUrl})`,
  borderImageSlice: '33%',
  borderImageRepeat: 'repeat',
  color: props.theme.colors.primary,
  display: 'inline-block',
  padding: '0.2rem',
  cursor: 'pointer',
  '&:hover, &:focus': {
    textDecoration: 'underline',
  },
}));

export default OrnamentalButton;
