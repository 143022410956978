// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import Badge from '../atoms/Badge';

type Props = {
  label: String,
  labelColor: String,
  value: String,
};

const StatsItem = (props: Props) => {
  const theme = useTheme();
  const { label, labelColor = theme.colors.primary, value } = props;

  return (
    <Root>
      <LabelSection>
        <Badge bgColor={labelColor} text={label} />
      </LabelSection>
      <ValueSection>{value}</ValueSection>
    </Root>
  );
};

const Root = styled.div({
  display: 'flex',
  margin: '1rem 0',
});

const LabelSection = styled.div({
  marginRight: '1rem',
  textAlign: 'right',
  width: '50%',
});

const ValueSection = styled.div({
  marginLeft: '1rem',
  width: '50%',
});

export default StatsItem;
