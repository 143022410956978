// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import SectionHeader from './SectionHeader';

type Props = {
  title: string,
  children: React.Node,
};

const Section = ({ title, children }: Props) => (
  <Root>
    <SectionHeaderLayout>
      <SectionHeader text={title} />
    </SectionHeaderLayout>
    {children}
  </Root>
);

const Root = styled.div((props) => ({
  padding: '3rem 1rem',
  [props.theme.mediaQuery.minTablet]: {
    padding: '5rem 1rem',
  },
}));

const SectionHeaderLayout = styled.div((props) => ({
  marginBottom: '1rem',
  [props.theme.mediaQuery.minTablet]: {
    marginBottom: '2rem',
  },
}));

export default Section;
